<template>
<div>
  <!-- 有内容 -->
  <div class="roll" v-if="detailInfo[0]">
    <div class="cont">
      <van-image class="logImage" fit="cover" :src="detailInfo[0].poster" />
      <div class="detail_bt_font_line">
        <div class="shanzhen_info">
          <!-- 标题-积分-start -->
          <div class="lvyou_hjb_title between">
            <p>
              <strong>超值价格：<span class="priceNum">¥{{ detailInfo[0].price}}</span></strong>
            </p>
          </div>
          <!-- 产品介绍 -->
          <div v-if="detailInfo[0].desc">
            <h3>产品介绍</h3>
            <p class="pinfo">
              {{ detailInfo[0].desc }}
              <span class="warning" v-if="detailInfo[0].price>0">此券出售后，不退不换，不支持过期退款，请确认后购买。</span>
            </p>
          </div>
         <div v-if="detailInfo[0].process[0]">
          <!-- 兑换流程 -->
          <h3>兑换流程</h3>
          <p class="pinfo" >
            <ol v-for="(item,index) in detailInfo[0].process" :key="item.index">
              <li>{{index+1}}、{{item.value}}</li>
            </ol>
          </p>
         </div>
          <!-- 使用说明 -->
          <div v-if="detailInfo[0].intro[0]">
            <h3>使用说明</h3>
            <p class="pinfo" >
               <ol v-for="(item,index) in detailInfo[0].intro " :key="item.index">
               <li>{{index+1}}、{{item.value}}</li>
               </ol>
            </p>
          </div>
          <!-- 有效期 -->
          <div v-if="detailInfo[0].expiration">
            <h3>有效期</h3>
            <p class="pinfo" >{{ detailInfo[0].expiration }}</p>
          </div>
         <!-- 其他说明 -->
         <div v-if="detailInfo[0].others">
            <h3>其他说明</h3>
            <p class="pinfo" >如有其他疑问，请联系10086咨询。</p>
         </div>
        </div>
      </div>
      
    </div>
    <!-- 支付 -->
    <van-action-sheet v-model="zfshow" title="选择支付方式">
      <div class="content">
        <!-- <p class="hbzf" @click="payB('hbzf')">
          <svg class="icon" aria-hidden="true">
             <use xlink:href="#iconhebaozhifu1"></use>
          </svg>和包支付</p> -->
        <p class="zfb" @click="payB('zfb')">
          <svg class="icon" aria-hidden="true">
             <use xlink:href="#iconzhifubaolight"></use>
          </svg>支付宝</p>
        <div v-if="!isInArray(detailInfo[0].id)">
           <p class="weixin" @click="payB('weixin')">
             <svg class="icon" aria-hidden="true">
             <use xlink:href="#iconweixin2"></use>
             </svg>微信支付</p>
        </div>
      </div>
    </van-action-sheet>
    <!-- 领取按钮 -->
    <div class="btn">
      <!-- receive(detailInfo[0].id) -->
      <van-button block @click="handleGet">{{msg}}</van-button>
    </div>
  </div>
  <!-- 无产品信息 -->
  <div class="roll none" v-else>
    暂无产品
  </div>
</div>

</template>
<script>
import { goodsapi, couponslq, pagelife, orderstatus } from "@/request/api";
import axios from "@/request/axios";
import { Dialog } from "vant";
export default {
  data() {
    return {
      msg: "立即购买",
      price: 0,
      titleName: "",
      data: {
        //首次获取的数据
        id: "",
        type: "",
        offset: 0,
        limit: 100,
        orderNo: "",
      },
      shopList: [], //获取的产品信息
      id: "",
      zfshow: false, //支付方式显示
      coupons: {
        //提交的内容
        goods_id: "",
        phone: "",
        orderNo: "",
        orderSt: "",
      },
      order: "", //领取成功
      pay: {
        //支付方式
        pay_type: "",
        order_no: "",
      },
      url: {}, //url链接参数
    };
  },
  // 一进入页面
  created() {
    const that = this;
    that.id = that.$route.query.id;
    that.coupons.goods_id = that.$route.query.id;
    that.data.type = that.$route.query.type;
    that.data.id = that.$route.query.id;
    that.data.orderNo = that.$route.query.orderNo;
    that.data.orderSt = that.$route.query.orderSt;
    this.URL_Request(); //执行获取url地址
    // 页面计时
    this.pageTiming();
    //数据请求
    this.rodetailList();
    this.zfshow = false;
    //检验订单数据
    this.checkOrder();
  },
  watch: {
    detailInfo:{
      handler(val) {
        // 价格-格式 11.00
        let num = val[0].price * 0.01;
        let p = Math.floor(num * 100) / 100;
        let s = p.toString(); // 把一个逻辑值转换为字符串，并返回结果；
        let rs = s.indexOf('.');  
        // 没有小数点时：
        if (rs < 0) { 
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + 2) {
            s += '0';
        }
        val[0].price = s;
      },
    },
    //数据监听
    $route: {
      //连接发生改变时
      handler(route) {
        const that = this;
        if (route.name == "rolldetail") {
          that.id = that.$route.query.id;
          that.data.id = that.$route.query.id;
          that.coupons.goods_id = that.$route.query.id;
          that.data.type = that.$route.query.type;
          this.rodetailList();
          this.zfshow = false;
          this.URL_Request(); //执行获取url地址
        }
      },
    },
    price: {
      handler(val) {
        const that = this;
        if (val == 0) {
          that.msg = "立即领取";
        } else {
          that.msg = "立即购买";
        }
      },
    },
  },
  methods: {
    // 页面计时
    pageTiming() {
      let phone = this.coupons.phone;
      pagelife({ phone: phone }).then(() => {});
    },
    //进入页面时，存在附带的订单号则检验订单是否支付
    checkOrder() {
        let order_no = this.coupons.orderNo;
        let order_status = this.coupons.orderSt;
        if(order_no!=''&&order_status==1) {
            // this.$toast.loading({
            //     duration: 2000, //持续展示loading
            //     forbidClick: true,
            //     message: "加载中"
            // });
            //检验订单状态 
            this.getOdStatus(order_no);
		}
	},
    // 数据请求
    rodetailList() {
      // let id = this.id;
      // goodsapi({id:id}).then((res) => {
      //   this.shopList = res.data.data;
      // });
      goodsapi(this.data).then((res) => {
        this.titleName = res.data.data[0].title;
        this.price = res.data.data[0].price;
        this.shopList = res.data.data;
      });
    },
    // 领取
    handleGet() {
      if (this.shopList[0].price == 0) {
        couponslq(this.coupons).then((res) => {
          const result = res.data;
          if (result.err_code == 0) {
            this.$toast.success("领取成功");
          } else {
            this.$toast.fail(result.err_msg);
          }
        });
      } else {
        Dialog.confirm({
          //提示
          title: "温馨提示",
          message: `此卡券需付费购买${
            "\n" + "<b>此券出售后，不退不换，不支持过期退款，请确认后购买。</b>"
          }`,
        })
          .then(() => {
            // 用户领取卡券
            couponslq(this.coupons).then((res) => {
              const result = res.data;
              if (result.err_code == 0) {
                this.order = result.data[0].order_no;
                this.pay.order_no = result.data[0].order_no;
                this.$toast.success("下单成功，支付即可使用");
                this.zfshow = true;
              } else {
                this.$toast.fail(result.err_msg);
              }
            });
          })
          .catch(() => {
            //失败
            // on cancel
          });
      }
    },

    //验证支付结果
    getOdStatus(orderId) {
        // let orderStatusInterval = setInterval(()=>{
            orderstatus({ order_id: orderId }).then((res) => {
                // clearInterval(orderStatusInterval);
                if (res.data.data==true) {
                    this.dialogMsg(orderId, 1);
                } else {
                    this.dialogMsg(orderId, 2);
                }
            });
        // }, 4000);
	},

    //再次验证支付结果
    getOdStatusTwo(orderId) {
        orderstatus({ order_id: orderId }).then((res) => {
            if (res.data.data==true) {
                this.dialogMsg(orderId, 1);
            } else {
                this.dialogMsg(orderId, 3);
            }
        });
    },

    // http://localhost:8080/#/rolldetail?id=65&type=3&sourceid=001065&scode=SV702180000089&orderNo=20220112104911665751&orderSt=1&token=62500b4324c3890b5a3d4de03c5508d00293f07105b0465f6dd85fff82934c07
    // http://localhost:8080/#/rolldetail?id=65&type=3&sourceid=001065&scode=SV702180000089&orderNo=20220112111043667043&orderSt=1&token=62500b4324c3890b5a3d4de03c5508d00293f07105b0465f6dd85fff82934c07
    //提示
    dialogMsg(orderId, type) {
		let msgTitle = this.titleName;
        if (type==1) {
            Dialog.confirm({
                //提示
                title: msgTitle+"购买成功",
                message: `${"<b>恭喜您，" + msgTitle + "购买成功，请到‘我的卡包’中使用；如需继续购买，请点击进入‘惠生活首页’。</b>"}`,
                confirmButtonText: "进入‘我的卡包’",
                cancelButtonText: "进入‘惠生活首页’",
            }).then(() => {
                // 点击进入‘我的卡包’
                // alert('点击了进入‘我的卡包’');
                window.open(`https://pcweb.mmarket.com/MobileAss/zj/coupons.html?m=`+this.coupons.phone);
            }).catch(() => {
                // 点击进入‘惠生活首页’
                window.open(`https://h5.ha.chinamobile.com/hnmccClientWap/h5-rest/#/life4Index`);
            });
		} else if (type==2) {
            Dialog.confirm({
                title: msgTitle+"购买失败",
                message: `${"<b>很抱歉，未获取到您的购买结果，请确认是否支付成功。</b>"}`,
                confirmButtonText: "支付成功",
                cancelButtonText: "支付失败",
            }).then(() => {
                // 点击支付成功
                this.$toast.clear();
                this.getOdStatusTwo(orderId);
            }).catch(() => {
                // 点击支付失败
                window.location.href=this.To_URL();
            });
		} else {
            Dialog.confirm({
                title: msgTitle+"购买失败",
                message: `${"<b>很抱歉，"+msgTitle+"购买失败，请确认是否支付成功，如您已付款成功，请点击刷新按钮。</b>"}`,
                confirmButtonText: "我知道了",
                cancelButtonText: "刷新重试",
            }).then(() => {
                // 点击我知道了 刷新页面
                window.location.href=this.To_URL();
            }).catch(() => {
                // 点击刷新重试
                this.$toast.clear();
                this.getOdStatus(orderId);
            });
		}
	},

	// 支付
    payB(type) {
      if (type == "hbzf") {
        this.pay.pay_type = "mcpay"; //和包支付
        axios
          .get(`/payment?pay_type=mcpay&order_no= ${this.pay.order_no}`)
          .then((res) => {
            if (res.data.err_msg == "成功") {
              window.open(res.data.data[0].url);
            } else {
              this.$toast.fail(res.data.err_msg);
            }
          });
      } else if (type == "zfb") {
        this.pay.pay_type = "alipay"; //支付宝
        // this.getOdStatus(this.pay.order_no);
		this.zfshow = false;
        window.open(`/payment?pay_type=alipay&order_no= ${this.pay.order_no}&phone=${this.coupons.phone}`);
      } else {
        this.pay.pay_type = "wxpay"; //微信支付
		// this.getOdStatus(this.pay.order_no);
		this.zfshow = false;
        window.open(`/payment?pay_type=wxpay&order_no= ${this.pay.order_no}&phone=${this.coupons.phone}`);
        //  this.$toast.fail('暂不支持微信支付');
      }
    },
	
    // 支付
    payB_bat(type) {
      if (type == "hbzf") {
        this.pay.pay_type = "mcpay"; //和包支付
        axios
          .get(`/payment?pay_type=mcpay&order_no= ${this.pay.order_no}`)
          .then((res) => {
            if (res.data.err_msg == "成功") {
              window.open(res.data.data[0].url);
            } else {
              this.$toast.fail(res.data.err_msg);
            }
          });
      } else if (type == "zfb") {
        this.pay.pay_type = "alipay"; //支付宝
        window.open(`/payment?pay_type=alipay&order_no= ${this.pay.order_no}&phone=${this.coupons.phone}`);
      } else {
        this.pay.pay_type = "wxpay"; //微信支付
        window.open(`/payment?pay_type=wxpay&order_no= ${this.pay.order_no}&phone=${this.coupons.phone}`);
        //  this.$toast.fail('暂不支持微信支付');
      }
    },
    // 正则获取url
    URL_Request() {
      var strHref = document.location.toString(); //获取url地址
      var intPos = strHref.indexOf("?"); //获取？后的参数
      var strRight = strHref.substr(intPos + 1); //获取到右边的参数部分
      var arrTmp = strRight.split("&"); //以&分割成数组
      for (var i = 0; i < arrTmp.length; i++) {
        //循环数组
        var dIntPos = arrTmp[i].indexOf("="); //在第几位
        var paraName = arrTmp[i].substr(0, dIntPos); //key
        var paraData = arrTmp[i].substr(dIntPos + 1); //value值
        // 将获取的值动态添加进对象里面
        this.$set(this.url, paraName, paraData);
        if (paraName == "sticket") {
          //判断是否有sticket参数
          this.coupons.phone = paraData; //有就赋值
        }else if (paraName == "orderNo") {
          this.coupons.orderNo = paraData;
        }else if (paraName == "orderSt") {
          this.coupons.orderSt = paraData;
        }
      }
      return "";
    },
    To_URL() {
        var new_url = "";
        var strHref = document.location.toString(); //获取url地址
        var intPos = strHref.indexOf("?"); //获取？后的参数
        var strRight = strHref.substr(intPos + 1); //获取到右边的参数部分
        var arrTmp = strRight.split("&"); //以&分割成数组
        for (var i = 0; i < arrTmp.length; i++) {
          //循环数组
          var dIntPos = arrTmp[i].indexOf("="); //在第几位
          var paraName = arrTmp[i].substr(0, dIntPos); //key
          var paraData = arrTmp[i].substr(dIntPos + 1); //value值
          // 将获取的值动态添加进对象里面
          this.$set(this.url, paraName, paraData);
          if (paraName != "orderSt") {
              new_url += paraName+'='+paraData+'&';
          }
        }
        new_url = new_url.substr(0, new_url.length - 1);
        return this.getHttp()+document.location.host+document.location.pathname+'#/rolldetail?'+new_url;
	},
    getHttp() {
        var ishttps = 'https:' == document.location.protocol ? true : false;
        if(ishttps) {
            return "https://";
        } else {
            return "http://";
        }
	},
    isInArray(value){
        let zshids = [268,269,270];
        for(var i = 0; i < zshids.length; i++){
            if(value == zshids[i]){
                return true;
            }
        }
        return false;
    }
  },
  // 计算属性（获取点击所属的产品）
  computed: {
    detailInfo: function () {
      const that = this;  
      //详细信息
      return that.shopList.filter(function (item) {
        return item.id == that.id;
      });
    },
  },
};
</script>

<style lang="less">
.roll {
  .cont {
    font-size: 14px;
    background: #fff;
    color: #333;
    margin: 0 auto;
    max-width: 750px;
    min-width: 320px;
    -webkit-user-select: none;
    user-select: none;
    .logImage {
      width: 100%;
      height: 10rem;
    }
    .detail_bt_font_line {
      padding: 0.5rem 0.5rem 3.5rem 0.5rem;
      .between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        .priceNum {
          color: red;
          font-size: 1.2rem;
        }
      }
      .shanzhen_info p {
        // padding-left: 9px;
        text-align: left;
        line-height: 1.2rem;
        color: #9b9999;
        text-align: justify;
      }
      .pinfo {
        padding: 0.6rem;
        width: 90%;
        margin: 0 auto;
        background: rgba(224, 224, 224, 0.2);
        border-radius: 5px;
        font-size: 0.8rem;
        text-indent: 0.6rem;
        // .symbol{  //符号（1 2）
        //    color: #fcc088;
        // }
      }
      .shanzhen_info h3 {
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0 0.3rem 0;
        color: rgb(252, 192, 136);
        position: relative;
      }
    }
  }
  .btn {
    width: 100%;
    height: 2.5rem;
    position: fixed;
    left: 0;
    bottom: 0.5rem;
    button {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      z-index: 98;
      font-size: 0.8rem;
      // background-color: #fe5729;
      height: 100%;
      border-radius: 0;
      color: #ad7960;
      background-image: linear-gradient(to right, #f3d9c4, #f5bd81);
      border-radius: 30px;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      color: white;
      background-color: #ad7960;
      border-color: #ad7960;
    }
  }
  .van-field__control {
    background-color: rgb(247, 247, 247);
    height: 2.5rem;
    width: 80%;
    margin: 0 auto;
    padding: 0 1rem;
  }
  .van-dialog__confirm,
  .van-dialog__confirm:active {
    color: #f18c08;
  }
  .content {
    padding: 16px 16px 20px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    p {
      height: 2.5rem;
      width: 100%;
      line-height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      color: rgb(253, 251, 251);
      font-size: 0.8rem;
    }
    // 和包支付
    .hbzf {
      background-color: rgb(252, 70, 70);
    }
    // 支付包支付
    .zfb {
      background-color: rgb(69, 155, 253);
    }
    // 微信支付
    .weixin {
      background-color: #07c160;
    }
  }
}
// 无产品
.none {
  text-align: center;
  color: #666666;
  margin-top: 50%;
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
}
b {
  display: block;
  margin-top: 1rem;
  color: #b70000;
  text-align: left;
}
.warning {
  display: block;
}
</style>





